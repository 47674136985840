#root > div:nth-child(2) {
  padding-top: 70px;
}

.ui, .dnarn {
  font-family: 'Ubuntu', sans-serif !important;
}

.sectionHeading {
  font-size: 3em !important;
}


.wrapper {
  position: relative;
}

#footer {
  padding: '20px 0 20px 0'; 
  position: 'fixed';
  bottom: 0;
  width: '100%';
  margin-top: 0px;
}

.customButtonBack {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}

.customButtonNext {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.showImage {
  max-height: 690px;
}

#about {
  min-height: 85vh;
}
.bio {
  min-height: 95vh;
}

.bio h1 {
  margin-top: 70px;
}


#home {
  min-height: 80vh;
  padding-top: 70px
}



#portfolio {
  margin-top: 70px;
  padding-bottom: 10px;
}

.gradient-background {
  background: linear-gradient(360deg,#14dbfe,#323232,#040404,#292929);
  background-size: 240% 240%;
  animation: gradient-animation 24s ease infinite;
}

.displayCard {
  margin-bottom: 10px !important;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 991px ) { 

  .showcase {
    max-height: 100%;
  }

  .displayCard {
    margin-bottom: 0 !important;
  }

}

@media only screen and (max-width: 767px) {
  [class*="mobile-hide"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large monitor only"]:not(.mobile),
  [class*="widescreen monitor only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }

  #home {
  min-height: 80vh;
  padding-top: 50px
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet-hide"],
  [class*="computer only"]:not(.tablet),
  [class*="large monitor only"]:not(.tablet),
  [class*="widescreen monitor only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}

.cls-1{
  isolation:isolate;
  }
.cls-2{
  fill:url(#radial-gradient);
  }
.cls-3{
  fill:url(#radial-gradient-2);
}
.cls-4,.cls-5,.cls-6{
  mix-blend-mode:multiply;
}
.cls-4{
  fill:url(#linear-gradient);
}
.cls-5{
  fill:url(#linear-gradient-2);
}
.cls-6{
  fill:url(#linear-gradient-3);
}
.cls-7{
  mix-blend-mode:screen;
  fill:url(#radial-gradient-3);
}